import {
    getMappingsFormLookups, getUserConnectorConfigurations,
    synchronizeMyobPaymentsToNeto, synchronizeNetoRmaToMyob,
    synchronizeNetoPaymentsToMyob, synchronizeNetoToMyobProducts,
    synchronizeMyobProductsToNeto, synchronizeMyobCustomersToNeto,
    synchronizeExistingCustomers, synchronizeExistingOrders,
    synchronizeExistingProducts, saveMappings,
    getMappingItemsByQuery, synchronizeOrdersOptions
} from '../../../../dataServices/MappingDataService';
import _ from 'lodash';
import { FormLookupsRequestResponse } from '../models/FormLookupsRequestResponse';
import { UserConnectorConfiguration } from '../models/UserConnectorConfiguration';
import { SynchronizeProcessRequestResponse } from '../models';
import { UserMappingModel, WarehouseCommunicationModel, WarehouseMappingsModel } from '../../../../shared/models/mappings/UserMappingModels';
import { ItemsRequestResponse } from '../models/ItemsRequestResponse';
import moment from 'moment';
import { FormsLookup } from '../../../../shared/models/mappings/FormLookups';
import { UserConnectorConfigurationModel } from '../../../../shared/models/UserConnectorConfigurationModel';
import { ValueLabel } from '../../../../shared/models';

export const getMappingsInitialValues = (): any => {
    const mappings = {
        SalesOrderMappings: {
            retreiveOrderWithStatuses: [],
            orderCanceled: false,
            orderNumberPrefix: '',
            orderStatus: [],
            splitkitComponentValue: false,
            isDiscountItemUsed: false,
            autoInvoiceNumber: false,
            selectedPaymentMethodMYOBToNeto: '',
            sendOrdersFromMYOBToNeto: false,
            sendPaymentsFromMYOBToNeto: false,
            defaultOnlineCustomerValue: '',
            dispatchedOrderDate: '',
            invoiceDeliveryStatus: '',
            assignCustomer: '',
            surchargeItem: '',
            shippingItem: '',
            discountItem: '',
            defaultLineItemAccount: '',
            assignJobCode: '',
            roundingItem: '',
            myobToNetoPaymentFrequency: '',
            isRmaSync: false,
            message: '',
            stockWriteOffAccount: '',
            rmaSync: '',
            lastRunAt: '',
            syncMessage: '',
            rmaLastRunAt: '',
            rmaSyncMessage: ''
        },
        ProductMappings: {
            isChecked: false,
            useIncomeAccount: '',
            useExpenseAccount: '',
            useSellingTax: '',
            useBuyingTax: '',
            useInventorizedItems: '',
            useIncomeAccountForInventorizedItems: '',
            useCOGSAccount: '',
            useInventoryAssestAccount: '',
            netoToMyobProduct: '',
            lastRunAt: '',
            syncMessage: '',
            syncErrorMessage: ''
        },
        ProductStockMappings: {
            stockQuantity: '',
            defaultWarehouse: '',
            excludedFields: [],
            isChecked: false,
            syncAs: '',
            isProductsPriceSync: '',
            myobToNetoProductFrequency: '',
            syncMessage: '',
            lastRunAt: '',
            syncErrorMessage: '',
        },
        SyncFeatures: {
            isChecked: false,
            accountTimezone: '',
            sendSyncReport: '',
            integrationsStartingDate: ''
        },
        PaymentMappings: {
            isChecked: false,
            depositPaymentInAccount: '',
            otherAccounts: [],
            // accCredit: '',
            lastRunAt: '',
            syncMessage: '',
            netoToMyobPaymentSync: '',
        },
        TaxCodeMappings: {
            sellingTaxForCustomers: '',
            noTaxExpense: '',
            noTaxReport: '',
            goodAndServiceTaxPercent: ''
        },
        WarehouseMappings: [],
        CustomerMyobToNeto: {
            isChecked: false,
            syncAs: "",
            myobToNetoCustomerFrequency: '',
        },
        CustomerNetoToMyob: {
            isChecked: false,
            syncAs: "",
            message: "",
            lastRunAt: ""
        },
        ExistingDataMappings: {
            isCheckedCustomer: false,
            isCheckedOrders: false,
            isCheckedProducts: false,
            syncCustomersAs: '',
            orderIdentity: '',
            orderType: '',
            syncProductsAs: '',
            orderIdentityPrefix: '',
            orderStartingDate: '',
            customerSyncMessage: '',
            customerLastRunAt: '',
            orderLastRunAt: '',
            orderSyncMessage: '',
            productLastRunAt: '',
            productSyncMessage: '',
            customerSyncErrorMessage: '',
            productSyncErrorMessage: '',
        },
        SyncProcessingStates: {

        }

    };

    return mappings;
}

export const getFormLookupsNetoToMyobMappings = async (userId: string, connectorId: string) => {
    const response = await getMappingsFormLookups(userId, connectorId);
    return <FormLookupsRequestResponse>{
        isSucceed: response.isSuccess,
        result: response.result,
        message: response.message,
    };
}

export const getUserConnectorConfiguration = async (userId: string, connectorId: string) => {
    const response = await getUserConnectorConfigurations(userId, connectorId);
    return response as UserConnectorConfiguration;
}

export const synchronizePaymentsMyobToNeto = async (userId: string, connectorId: string) => {
    const response = await synchronizeMyobPaymentsToNeto(userId, connectorId);
    return response as SynchronizeProcessRequestResponse;
}

export const synchronizeRMANetoToMyob = async (userId: string, connectorId: string) => {
    const response = await synchronizeNetoRmaToMyob(userId, connectorId);
    return response as SynchronizeProcessRequestResponse;
}

export const synchronizePaymentsNetoToMyob = async (userId: string, connectorId: string) => {
    const response = await synchronizeNetoPaymentsToMyob(userId, connectorId);
    return response as SynchronizeProcessRequestResponse;
}

export const synchronizeProductsNetoToMyob = async (userId: string, connectorId: string) => {
    const response = await synchronizeNetoToMyobProducts(userId, connectorId);
    return response as SynchronizeProcessRequestResponse;
}

export const synchronizeProductsMyobToNeto = async (userId: string, connectorId: string) => {
    const response = await synchronizeMyobProductsToNeto(userId, connectorId);
    return response as SynchronizeProcessRequestResponse;
}

export const synchronizeCustomersMyobToNeto = async (userId: string, connectorId: string) => {
    const response = await synchronizeMyobCustomersToNeto(userId, connectorId);
    return response as SynchronizeProcessRequestResponse;
}

export const synchronizeExistingSystemCustomers = async (userId: string, connectorId: string) => {
    const response = await synchronizeExistingCustomers(userId, connectorId);
    return response as SynchronizeProcessRequestResponse;
}

export const synchronizeExistingSystemOrders = async (userId: string, connectorId: string) => {
    const response = await synchronizeExistingOrders(userId, connectorId);
    return response as SynchronizeProcessRequestResponse;
}

export const synchronizeExistingSystemProducts = async (userId: string, connectorId: string) => {
    const response = await synchronizeExistingProducts(userId, connectorId);
    return response as SynchronizeProcessRequestResponse;
}

export const synchronizationOrders = async (userId: string, connectorId: string) => {
    const response = await synchronizeOrdersOptions(userId, connectorId);
    return response as SynchronizeProcessRequestResponse;
}

export const saveUserMappings = async (userId: string, connectorId: string, data: UserMappingModel, lastModifiedData: any) => {
    const persistentMappings = (await getUserConnectorConfiguration(userId, connectorId))?.result?.mappings;
    const omittedData = omitSyncInfoPropertiesFromUserMappings(data);
    // const userLastModified = moment(new Date).format("DD MMM yyyy hh:mm A");
    if (!_.isEmpty(persistentMappings) && !_.isEmpty(omittedData)) {
        Object.assign(persistentMappings.myobCustomerToNeto, omittedData.myobCustomerToNeto);
        Object.assign(persistentMappings.existingData, omittedData.existingData);
        Object.assign(persistentMappings.paymentMapping, omittedData.paymentMapping);
        Object.assign(persistentMappings.netoCustomerToMyob, omittedData.netoCustomerToMyob);
        Object.assign(persistentMappings.productStockMapping, omittedData.productStockMapping);
        Object.assign(persistentMappings.salesOrderMapping, omittedData.salesOrderMapping);
        Object.assign(persistentMappings.synchronizationFeatures, data.synchronizationFeatures);
        Object.assign(persistentMappings.taxCodeMappings, data.taxCodeMappings);
        Object.assign(persistentMappings.warehouseMapping, data.warehouseMapping);
        Object.assign(persistentMappings.itemMapping, data.itemMapping);

        const response = await saveMappings(userId, connectorId, persistentMappings, lastModifiedData);
        return response as SynchronizeProcessRequestResponse;
    }
    else {
        const response = await saveMappings(userId, connectorId, omittedData, lastModifiedData);
        return response as SynchronizeProcessRequestResponse;
    }
}

const omitSyncInfoPropertiesFromUserMappings = (data: UserMappingModel) => {
    let omittedMappings: any = {};
    omittedMappings.myobCustomerToNeto = _.omit(data.myobCustomerToNeto, ["lastRunAt", "message", "syncErrorMessage"]);
    omittedMappings.existingData = _.omit(data.existingData, ["customerLastRunAt", "customerSyncMessage", "orderLastRunAt", "orderSyncMessage", "productLastRunAt", "productSyncMessage", "customerSyncErrorMessage", "productSyncErrorMessage"]);
    omittedMappings.paymentMapping = _.omit(data.paymentMapping, ["lastRunAt", "syncMessage"]);
    omittedMappings.netoCustomerToMyob = _.omit(data.netoCustomerToMyob, ["lastRunAt", "message", "syncErrorMessage"]);
    omittedMappings.productStockMapping = _.omit(data.productStockMapping, ["lastRunAt", "syncMessage", "syncErrorMessage"]);
    omittedMappings.salesOrderMapping = _.omit(data.salesOrderMapping, ["orderLastRunAt", "orderSyncMessage", "rmaLastRunAt", "rmaSyncMessage", "lastRunAt", "syncMessage"]);
    omittedMappings.synchronizationFeatures = data.synchronizationFeatures;
    omittedMappings.taxCodeMappings = data.taxCodeMappings;
    omittedMappings.warehouseMapping = data.warehouseMapping;
    omittedMappings.itemMapping = _.omit(data.itemMapping, ["lastRunAt", "message", "syncErrorMessage"]);
    return omittedMappings;
}

export const getMappingItemsBySearch = async (userId: string, connectorId: string, query: string) => {
    const response = await getMappingItemsByQuery(userId, connectorId, query);
    return response as ItemsRequestResponse;
}

export const validateUserMappingsAgainstFormLookups = (userConfig: UserConnectorConfigurationModel, lookups: FormsLookup) => {
    let validatedMappings: UserMappingModel = userConfig?.mappings ?? {} as UserMappingModel;
    if (!_.isEmpty(userConfig?.mappings)) {
        validatedMappings = {
            ...validatedMappings,
            existingData: {
                ...validatedMappings.existingData,
                orderIdentity: getValidatedValue(lookups?.existingData?.orderIdentity?.values, userConfig?.mappings?.existingData?.orderIdentity),
                orderIdentityPrefix: userConfig?.mappings?.existingData?.orderIdentityPrefix ?? '',
                orderStartingDate: userConfig?.mappings?.existingData?.orderStartingDate ?? '',
                orderType: getValidatedValue(lookups?.existingData?.orderType?.values, userConfig?.mappings?.existingData?.orderType),
                syncProductsAs: getValidatedValue(lookups?.genericSyncOptions?.values, userConfig?.mappings?.existingData?.syncProductsAs),
                syncCustomersAs: getValidatedValue(lookups?.existingData?.syncCustomersAs?.values, userConfig?.mappings?.existingData?.syncCustomersAs),
            },
            itemMapping: {
                ...validatedMappings.itemMapping,
                netoToMyobProduct: userConfig?.mappings?.itemMapping?.netoToMyobProduct ?? '',
                useSellingTax: getValidatedValue(lookups?.itemMapping?.sellingTax?.values, userConfig?.mappings?.itemMapping?.useSellingTax),
                useBuyingTax: getValidatedValue(lookups?.itemMapping?.buyingTax?.values, userConfig?.mappings?.itemMapping?.useBuyingTax),
                useCOGSAccount: getValidatedValue(lookups?.itemMapping?.COGSAccount?.values, userConfig?.mappings?.itemMapping?.useCOGSAccount),
                useExpenseAccount: getValidatedValue(lookups?.itemMapping?.expenseAccount?.values, userConfig?.mappings?.itemMapping?.useExpenseAccount),
                useIncomeAccount: getValidatedValue(lookups?.itemMapping?.incomeAccount?.values, userConfig?.mappings?.itemMapping?.useIncomeAccount),
                useIncomeAccountForInventorizedItems: getValidatedValue(lookups?.itemMapping?.incomeAccountForInventorizedItems?.values, userConfig?.mappings?.itemMapping?.useIncomeAccountForInventorizedItems),
                useInventorizedItems: getValidatedValue(lookups?.itemMapping?.inventorizedItems?.values, userConfig?.mappings?.itemMapping?.useInventorizedItems),
                useInventoryAssestAccount: getValidatedValue(lookups?.itemMapping?.inventoryAssestAccount?.values, userConfig?.mappings?.itemMapping?.useInventoryAssestAccount),
            },
            paymentMapping: {
                ...validatedMappings.paymentMapping,
                // accCredit: getValidatedValue(lookups?.paymentMapping?.accountCredit?.values, userConfig?.mappings?.paymentMapping?.accCredit),
                depositPaymentInAccount: getValidatedValue(lookups?.paymentMapping?.depositPaymentIntoAccount?.values, userConfig?.mappings?.paymentMapping?.depositPaymentInAccount),
                netoToMyobPaymentSync: getValidatedValue(lookups?.genericSchedulerFrequency?.values, userConfig?.mappings?.paymentMapping?.netoToMyobPaymentSync),
                // otherAccounts: 
            },
            productStockMapping: {
                ...validatedMappings.productStockMapping,
                defaultWarehouse: getValidatedValue(lookups?.productStockMapping?.defaultWarehouse?.values, userConfig?.mappings?.productStockMapping?.defaultWarehouse),
                syncAs: getValidatedValue(lookups?.genericSyncOptions?.values, userConfig?.mappings?.productStockMapping?.syncAs),
                myobToNetoProductFrequency: getValidatedValue(lookups?.genericSchedulerFrequency?.values, userConfig?.mappings?.productStockMapping?.myobToNetoProductFrequency),
                stockQuantity: getValidatedValue(lookups?.productStockMapping?.stockQuantity?.values, userConfig?.mappings?.productStockMapping?.stockQuantity),
                excludedFields: getValidatedValueArray(lookups?.productStockMapping?.excludedFields?.values, userConfig?.mappings?.productStockMapping?.excludedFields),

            },
            salesOrderMapping: {
                ...validatedMappings.salesOrderMapping,
                retreiveOrderWithStatuses: getValidatedValueArray(lookups?.retriveOrdersFromNeto?.retreiveOrderWithStatuses?.values, userConfig?.mappings?.salesOrderMapping?.retreiveOrderWithStatuses),
                // orderStatus
                stockWriteOffAccount: getValidatedValue(lookups?.retriveOrdersFromNeto?.stockWriteOffAccount?.values, userConfig?.mappings?.salesOrderMapping?.stockWriteOffAccount),
                rmaSync: getValidatedValue(lookups?.genericSchedulerFrequency?.values, userConfig?.mappings?.salesOrderMapping?.rmaSync),
                dispatchedOrderDate: getValidatedValue(lookups?.retriveOrdersFromNeto?.advanceOptions?.dateForDispatchedOrders?.values, userConfig?.mappings?.salesOrderMapping?.dispatchedOrderDate),
                surchargeItem: getValidatedValue(lookups?.retriveOrdersFromNeto?.advanceOptions?.surChargeItem?.values, userConfig?.mappings?.salesOrderMapping?.surchargeItem),
                shippingItem: getValidatedValue(lookups?.retriveOrdersFromNeto?.advanceOptions?.shippingItem?.values, userConfig?.mappings?.salesOrderMapping?.shippingItem),
                discountItem: getValidatedValue(lookups?.retriveOrdersFromNeto?.advanceOptions?.discountItem?.values, userConfig?.mappings?.salesOrderMapping?.discountItem),
                roundingItem: getValidatedValue(lookups?.retriveOrdersFromNeto?.advanceOptions?.roundingItem?.values, userConfig?.mappings?.salesOrderMapping?.roundingItem),
                defaultLineItemAccount: getValidatedValue(lookups?.retriveOrdersFromNeto?.advanceOptions?.defaultLineItemAccount?.values, userConfig?.mappings?.salesOrderMapping?.defaultLineItemAccount),
                assignCustomer: getValidatedValue(lookups?.retriveOrdersFromNeto?.advanceOptions?.customers?.values, userConfig?.mappings?.salesOrderMapping?.assignCustomer),
                invoiceDeliveryStatus: getValidatedValue(lookups?.retriveOrdersFromNeto?.advanceOptions?.deliveryStatus?.values, userConfig?.mappings?.salesOrderMapping?.invoiceDeliveryStatus),
                myobToNetoPaymentFrequency: getValidatedValue(lookups?.genericSchedulerFrequency?.values, userConfig?.mappings?.salesOrderMapping?.myobToNetoPaymentFrequency),
                selectedPaymentMethodMYOBToNeto: getValidatedValue(lookups?.retriveOrdersFromNeto?.paymentMapping?.paymentMethod?.values, userConfig?.mappings?.salesOrderMapping?.selectedPaymentMethodMYOBToNeto),
            },
            myobCustomerToNeto: {
                ...validatedMappings.myobCustomerToNeto,
                syncAs: getValidatedValue(lookups?.genericSyncOptions?.values, userConfig?.mappings?.myobCustomerToNeto?.syncAs),
                myobToNetoCustomerFrequency: getValidatedValue(lookups?.genericSchedulerFrequency?.values, userConfig?.mappings?.myobCustomerToNeto?.myobToNetoCustomerFrequency),
            },
            synchronizationFeatures: {
                ...validatedMappings.synchronizationFeatures,
                accountTimezone: userConfig?.mappings?.synchronizationFeatures?.accountTimezone ?? '',
                integrationsStartingDate: userConfig?.mappings?.synchronizationFeatures?.integrationsStartingDate ?? '',
                sendSyncReport: getValidatedValue(lookups?.synchronizationFeatures?.syncReportEmail?.values, userConfig?.mappings?.synchronizationFeatures?.sendSyncReport),
            },
            taxCodeMappings: {
                ...validatedMappings.taxCodeMappings,
                sellingTaxForCustomers: getValidatedValue(lookups?.taxCodeMapping?.defaultSellingTaxCode?.values, userConfig?.mappings?.taxCodeMappings?.sellingTaxForCustomers),
                noTaxExpense: getValidatedValue(lookups?.taxCodeMapping?.noTaxEXP?.values, userConfig?.mappings?.taxCodeMappings?.noTaxExpense),
                goodAndServiceTaxPercent: getValidatedValue(lookups?.taxCodeMapping?.taxGST?.values, userConfig?.mappings?.taxCodeMappings?.goodAndServiceTaxPercent),
            },
            // warehouseMapping: getValidatedValueWarehouse(lookups, userConfig?.mappings?.warehouseMapping)
        };
    }

    return validatedMappings;
}

const getValidatedValue = (values: Array<ValueLabel>, value: string) => {
    if (values?.some((x) => x.value === value)) {
        return value;
    }
    else {
        return '';
    }
}

const getValidatedValueArray = (values: Array<ValueLabel>, value: Array<string>) => {
    let optionValues = _.map(values, 'value');
    if (optionValues && optionValues?.some((x) => value?.includes(x))) {
        return value;
    }
    else {
        return [];
    }
}

const getValidatedValueWarehouse = (lookups: FormsLookup, warehouseMapping: Array<WarehouseMappingsModel>) => {
    const myobLocationIds = _.map(lookups?.warehouseMapping?.myobLocations, 'value')
    if (myobLocationIds && warehouseMapping && myobLocationIds.some((x) => warehouseMapping.some((y) => x === y.myobLocationId))) {
        return warehouseMapping;
    } else {
        return [];
    }
}

export const evaluateWarehouseMappings = (warehouseLookups: Array<ValueLabel>, warehouseMappings: Array<WarehouseMappingsModel>,
    warehouseCommunicationModel: Array<WarehouseCommunicationModel>) => {
    let mappingArr: Array<WarehouseCommunicationModel> = [];
    warehouseLookups?.map((warehouse) => {
        if (warehouseMappings && warehouseMappings.length > 0) {
            let house = warehouseMappings?.find((x: any) => warehouse.value === x.netoWarehouseId);
            if (house) {
                mappingArr.push(
                    {
                        warehouse: warehouse,
                        identifier: house.Identifier,
                        selectedValue: house.myobLocationId,
                        selectedName: house.myobLocationName,
                        isError: _.isEmpty(house.myobLocationId),
                        isTouched: true
                    });
            }
            else {
                mappingArr.push(
                    {
                        warehouse: warehouse,
                        identifier: '',
                        selectedValue: '',
                        selectedName: '',
                        isTouched: false,
                        isError: false,
                    });
            }
        }
        else if (warehouseCommunicationModel) {
            // let house = warehouseMappings?.find((x: any) => warehouse.value === x.warehouse.value);
            const house = _.findIndex(warehouseCommunicationModel, function (chr: any) {
                return chr.warehouse.value === warehouse.value;
            });

            if (house >= 0) {
                mappingArr.push(
                    {
                        warehouse: warehouse,
                        identifier: warehouseCommunicationModel[house].identifier,
                        selectedValue: warehouseCommunicationModel[house].selectedValue,
                        selectedName: warehouseCommunicationModel[house].selectedName,
                        isError: _.isEmpty(warehouseCommunicationModel[house].selectedValue),
                        isTouched: true
                    });
            }
            else {
                mappingArr.push(
                    {
                        warehouse: warehouse,
                        identifier: '',
                        selectedValue: '',
                        selectedName: '',
                        isError: false,
                        isTouched: false
                    });
            }
        }
        else {
            mappingArr.push(
                {
                    warehouse: warehouse,
                    identifier: '',
                    selectedValue: '',
                    selectedName: '',
                    isError: false,
                    isTouched: false
                });
        }
    });

    return mappingArr;
}


export const validateWarehouseMappings = (WarehouseMappings: Array<WarehouseCommunicationModel>) => {
    let ware = [...WarehouseMappings];

    if (ware && ware.length > 0) {
        ware.map((obj) => {
            obj.isTouched = true;
            if (_.isEmpty(obj.selectedValue)) {
                obj.isError = true;
            } else {
                obj.isError = false;
            }
        })
    }
    return ware;
}