export const CLOUD_FUNCTIONS = {
  // ON_ORDER_FROM_NETO: "onOrderFromNeto",
  ORDER_PROCESSING_NETO_TO_MYOB_TASK: "orderProcessingNetoToMyobTask",
  NETO_AUTHENTICATION: "netoAuthentication",
  GET_UI_MAPPING: "getUiMapping",
  PRODUCT_SYNC_MYOB_TO_NETO: "productSyncMyobToNetoTask",
  CUSTOMER_SYNC_MYOB_TO_NETO: "customerSyncMyobToNetoTask",
  ORDER_PAYMENT_SYNC_MYOB_TO_NETO: "orderPaymentsSyncMyobToNetoTask",
  ORDER_PAYMENT_SYNC_NETO_TO_MYOB: "orderPaymentsSyncNetoToMyobTask",
  MYOB_AUTHENTICATION: "myobAuthentication",
  EXISTING_ORDER_IMPORTING: "existingOrderImporting",
  EXISTING_CUSTOMERS_MAPPING: "existingCustomersMapping",
  EXISTING_PRODUCTS_IMPORTING: "existingProductImporting",
  UPDATE_SYSTEM_USERS: "updateUsers",
  GET_SYSTEM_USERS: "getAllUsers",
  ADMIN_API: "adminApi",
  NETO_AUTH: "auth",
  NETO_DEAUTH: "deauth",
  NETO_DEAUTH_URI: "neto",
  GET_NETO_AUTH_URI: "getNetoAuthUri",
  NETO_RMA_SYNC_TASK: "netoRmaSyncMyobCreditTask",
  PRODUCT_SYNC_NETO_TO_MYOB_TASK: "productSyncNetoToMyobTask",
  REPROCESS_BULK_ORDERS: "bulkOrderProcessingNetoToMyobTask",
  GET_UI_MAPPING_ITEMS: "getUiMappingItems",
  PROCESS_BULK_ORDERS: "processBulkOrders",
  LOGGING: "logging",
  MARK_AS_CLOSE_ALL_ORDERS: "custom/invoices/myob/firebase/closed/update",
  MARK_AS_CLOSE_ORDERS: "custom/orders-firebase/update",
  RE_EXPORT_NETO_RMA_TO_MYOB: "reExportNetoRmaToMyobCreditTask",
};

export const SUBSCRIPTION_STATUSES = {
  ACTIVE: "active",
  PAST_DUE: "past_due",
  UNPAID: "unpaid",
  CANCELLED: "canceled",
  INCOMPLETE: "incomplete",
  INCOMPLETE_EXPIRED: "incomplete_expired",
  TRIALING: "trialing"
}

export const DATABASE_COLLECTIONS = {
  USER_DETAILS: "user-details",
  CONNECTORS: "connectors",
  ORDERS: "orders",
  RECORDCOUNTER: "recordsCounter",
  CUSTOMERS: "customers",
  PRODUCTS: "products",
  PRICES: "prices",
  CHECKOUT_SESSIONS: "checkout_sessions",
  TRIGGER_MAIL: "trigger_mail",
  CONNECTOR_CONFIGURATION: "connector-configuration",
  FAILED_SYNC_PRODUCTS: "failed-sync-products",
  SUCCESS_SYNC_PRODUCTS: "success-sync-products",
  FAILED_SYNC_CUSTOMERS: "failed-sync-customers",
  SUCCESS_SYNC_CUSTOMERS: "success-sync-customers",
}

export const STATIC_DOCUMENTS_ID = {
  ORDER: 'order',
  PRODUCT: 'product',
  CUSTOMER: 'customer'
}

export const STRIPE_PUBLISHABLE_KEY = "pk_test_51IIUm0KsuYyFXhSvPIN8vpVEOwJuLMLVqoqBEwPVOXO3RC2Rh8CTRs2kxWbK51SQWsR8mBvAIltGDMY0bjheLavT00NKFlsOxO";
export const NETO_DOMAINS = [".neto.com.au", ".com.au", ".com", "mymaropost.com"];

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;
export const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;


